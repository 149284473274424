<template>
  <div>
   <dx-util-popup
      ref="productDetailsRef"
      :drag-enabled="false"
      :close-on-outside-click="true"
      :show-close-button="true"
      :show-title="true"
      width="90vw"
      height="80vh"
      title="Product Details"
      :on-hiding="onHiding"
      class="max-width-800"
    >
      <template #content>
        <div>
          <div class="d-flex pb-1 justify-content-between align-items-start">
            <div class="d-flex">
              <img :src="product.imageUrl" class="img-fluid rounded text-white limit-size">
              <div class="m-1 font-weight-bold">
                {{ product.title }}
              </div>
            </div>
            <div class="text-center ml-2">
              <span class="text-caption">
                ACCOUNT
              </span>
              <div class="text-warning font-weight-bold">
                {{ product.accountNo }}
              </div>
            </div>
          </div>
          <dx-util-tab-panel height="85%" :animation-enabled="true" :defer-rendering="true" :show-nav-buttons="true">
            <dx-util-panel-item title="Summary" icon="bi bi-info-square">
              <template #default>
                <dx-util-scroll-view width="100%" height="100%">
                    <div class="col-md-12 px-0">
                    <b-table :items="summaryData" :fields="summaryFields">
                      <template #cell(value)="{item}">
                        <span v-if="item.title === 'Plan' && hasPermission" class="font-weight-bold text-uppercase">
                          <dx-util-button
                            :text="item.value ? item.value : 'Select a plan'"
                            :hint="'Select a plan'"
                            type="default"
                            :styling-mode="item.value ? 'outlined' : 'filled'"
                            @click="openPlanPopup"
                          />
                        </span>
                        <span v-else class="font-weight-bold text-uppercase">
                          {{ item.value }}
                        </span>
                      </template>
                    </b-table>
                  </div>
                </dx-util-scroll-view>
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item title="MSKU-FNSKU Pairs" icon="bi bi-upc">
              <template #default>
                <dx-util-scroll-view width="100%" height="100%">
                  <div class="col-md-12 px-0">
                    <b-table :items="mskuFnskuPairs" :fields="mskuFnskuPairFields">
                      <template #cell(value)="{ item }">
                        <span class="font-weight-bold">
                          {{ item.value }}
                        </span>
                      </template>
                    </b-table>
                  </div>
                </dx-util-scroll-view>
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item title="Images" icon="bi bi-images">
              <template #default>
                <dx-util-scroll-view width="100%" height="100%">
                  <div class="d-flex flex-wrap justify-content-center align-items-center">
                    <img
                      v-for="(image, i) in images" :key="i"
                      :src="image.url"
                      class="img-fluid rounded text-white square m-1"
                      @click="showImage(image.url)"
                    >
                  </div>
                </dx-util-scroll-view>
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item title="Dimensions" icon="bi bi-rulers">
              <template #default>
                <dx-util-scroll-view width="100%" height="100%">
                  <div class="col-md-12 px-0">
                    <b-table :items="dimensionsData" :fields="dimensionFields">
                      <template #cell(value)="{ item }">
                        <span class="font-weight-bold">
                          {{ item.value }}
                        </span>
                      </template>
                      <template #cell(title)="{ item }">
                        <span class="text-capitalize">
                          {{ item.title }}
                        </span>
                      </template>
                    </b-table>
                  </div>
                </dx-util-scroll-view>
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item title="Rankings" icon="bi bi-graph-up-arrow">
              <template #default>
                <dx-util-scroll-view width="100%" height="100%">
                  <div class="col-md-12 px-0">
                    <b-table :items="rankingData" :fields="rankingFields">
                      <template #cell(value)="{ item }">
                        <span class="font-weight-bold">
                          {{ item.value }}
                        </span>
                      </template>
                      <template #cell(title)="{ item }">
                        <span class="text-capitalize">
                          {{ item.title }}
                        </span>
                      </template>
                    </b-table>
                  </div>
                </dx-util-scroll-view>
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item title="Default Charges" icon="bi bi-currency-dollar">
              <template #default>
                <dx-util-scroll-view width="100%" height="100%">
                  <div class="col-md-12 px-0">
                    <b-table :items="charges" :fields="chargeFields">
                      <template #cell(cost)="{ item }">
                        <span class="font-weight-bold">
                          $ {{ item.cost }}
                        </span>
                      </template>
                    </b-table>
                  </div>
                </dx-util-scroll-view>
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item title="Inbound History" icon="bi bi-box-arrow-in-right">
              <template #default>
                <dx-util-scroll-view width="100%" height="100%">
                  <div class="col-md-12 px-0">
                    <product-inbound-history :key="rerenderKey" :product-id="productId" />
                  </div>
                </dx-util-scroll-view>
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item title="Inventory History" icon="bi bi-stack">
              <template #default>
                <dx-util-scroll-view width="100%" height="100%">
                  <div class="col-md-12 px-0">
                    <product-inventory-history :key="rerenderKey" :product-id="productId" />
                  </div>
                </dx-util-scroll-view>
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item title="FBA Outbound History" icon="bi bi-truck">
              <template #default>
                <dx-util-scroll-view width="100%" height="100%">
                  <div class="col-md-12 px-0">
                    <product-outbound-history :key="rerenderKey" :product-id="productId" />
                  </div>
                </dx-util-scroll-view>
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item title="Outbound History (Others)" icon="icon icon-psh-outbound">
              <template #default>
                <dx-util-scroll-view width="100%" height="100%">
                  <div class="col-md-12 px-0">
                    <other-product-outbound-history :key="rerenderKey" :product-id="productId" />
                  </div>
                </dx-util-scroll-view>
              </template>
            </dx-util-panel-item>
          </dx-util-tab-panel>
          <div class="col-md-12 px-0">
            <light-box
              :items="fullImages"
              :index="index"
              :effect="'fade'"
              @close="index = null"
            />
          </div>
        </div>
      </template>
    </dx-util-popup>
    <plan-selector
      :show-plan-selector="isVisible"
      :plan-id="product.planId"
      :product-id="productId"
      :account-no="product.accountNo"
      :company-id="product.companyId"
      @close="isVisible=false"
      @planUpdated="getProductDetails()"
    />
  </div>
</template>

<script>
import productsService from '@/http/requests/product/productsService'
import CoolLightBox from 'vue-cool-lightbox'

export default {
  components: {
    'light-box': CoolLightBox,
    'plan-selector': () => import('@/views/apps/product/products/components/PlanSelector'),
    'product-inbound-history': () => import('@/views/apps/product/products/components/ProductInboundHistory'),
    'product-inventory-history': () => import('@/views/apps/product/products/components/ProductInventoryHistory.vue'),
    'product-outbound-history': () => import('@/views/apps/product/products/components/ProductOutboundHistory.vue'),
    'other-product-outbound-history': () => import('@/views/apps/product/products/components/OtherProductOutboundHistory.vue'),
  },
  props: {
    productId: {
      type: Number || null,
      default: null,

    },
    showProduct: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      product: {},
      index: null,
      isVisible: false,
      defaultVisible: false,
      rerenderKey: 0,
      fullImages: [],
      summaryFields: [
        {
          key: 'title', label: 'ATTRIBUTE', sortable: false, class: 'text-left', thStyle: { width: '30%' },
        },
        {
          key: 'value', label: 'DESCRIPTION', sortable: false, class: 'text-left', thStyle: { width: '70%' },
        },
      ],
      mskuFnskuPairFields: [
        {
          key: 'msku', label: 'MSKU', sortable: false, class: 'text-left', thStyle: { width: '40%' },
        },
        {
          key: 'fnsku', label: 'FNKSU', sortable: false, class: 'text-left', thStyle: { width: '60%' },
        },
      ],
      chargeFields: [
        {
          key: 'title', label: 'CHARGE TYPE', sortable: false, class: 'text-left', thStyle: { width: '30%' },
        },
        {
          key: 'cost', label: 'RATE', sortable: false, class: 'text-left', thStyle: { width: '70%' },
        },
      ],
      dimensionFields: [
        {
          key: 'title', label: 'ATTRIBUTE', sortable: false, class: 'text-left', thStyle: { width: '20%' },
        },
        {
          key: 'value', label: 'DESCRIPTION', sortable: false, class: 'text-left', thStyle: { width: '80%' },
        },
      ],
      rankingFields: [
        {
          key: 'title', label: 'RANKING TITLE', sortable: false, class: 'text-left', thStyle: { width: '40%' },
        },
        {
          key: 'rank', label: 'RANK', sortable: false, class: 'text-left', thStyle: { width: '60%' },
        },
      ],
    }
  },
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
    summaryData() {
      const combinedData = [
        { title: 'Manufacturer', value: this.product.manufacturer },
        { title: 'UPC', value: this.product.upc },
        { title: 'ASIN', value: this.product.asin },
      ]
      if (this.product.catalogItem !== null
          && this.product.catalogItem.identifiers !== null
          && this.product.catalogItem.identifiers.length > 0
          && this.product.catalogItem.identifiers[0] !== null
          && this.product.catalogItem.identifiers[0].identifier !== null) {
        let identifiers = this.product.catalogItem.identifiers[0].identifiers.map(item => ({ title: item.identifierType, value: item.identifier }))
        if (this.product.upc !== null) {
          identifiers = identifiers.filter(item => item.title !== 'UPC')
        }
        combinedData.push(...identifiers)
      }
      combinedData.push(
        { title: 'Plan', value: this.product.planName },
        { title: 'Pack Type', value: this.product.packType },
        { title: 'Hazardous (Hazmat)', value: this.product.isHazmat },
      )
      const productTypes = this.product.catalogItem.productTypes.map(item => item.productType).toString()
      combinedData.push({ title: 'Product Types', value: productTypes })
      if (this.product.catalogItem !== null
          && this.product.catalogItem.summaries !== null
          && this.product.catalogItem.summaries.length > 0) {
        const content = this.product.catalogItem.summaries[0]
        combinedData.push(
          { title: 'Brand', value: content.brand },
          { title: 'Color', value: content.color },
          { title: 'Model Number', value: content.modelNumber },
          { title: 'Size', value: content.size },
          { title: 'Style', value: content.style },
          { title: 'Part Number', value: content.partNumber },
        )
      }
      return combinedData
    },
    mskuFnskuPairs() {
      return this.product.mskuFnskuPairs.map(item => ({ msku: item.msku, fnsku: item.fnsku }))
    },
    images() {
      let combinedData = []
      if (this.product.catalogItem !== null
          && this.product.catalogItem.images !== null
          && this.product.catalogItem.images.length > 0
          && this.product.catalogItem.images[0] !== null
          && this.product.catalogItem.images[0].images !== null
          && this.product.catalogItem.images[0].images.length > 0) {
        combinedData = this.product.catalogItem.images[0].images.map(item => ({ url: item.link }))
      }
      return combinedData
    },
    charges() {
      let combinedData = []
      if (this.product.defaultCharges !== null) {
        combinedData = this.product.defaultCharges
      }
      return combinedData
    },
    dimensionsData() {
      const combinedData = []
      if (this.product.catalogItem !== null
            && this.product.catalogItem.dimensions !== null
            && this.product.catalogItem.dimensions.length > 0) {
        let dimensions = Object.keys(this.product.catalogItem.dimensions[0].package)
          .map(key => ({ title: key, value: this.product.catalogItem.dimensions[0].package[key] }))
        dimensions = dimensions.map(item => ({ title: item.title, value: `${item.value.value.toFixed(2)} ${item.value.unit}` }))
        combinedData.push(...dimensions)
      }
      return combinedData
    },
    rankingData() {
      const combinedData = []
      if (this.product.catalogItem !== null && this.product.catalogItem.salesRanks !== null
          && this.product.catalogItem.salesRanks.length > 0
          && this.product.catalogItem.salesRanks[0] !== null
          && this.product.catalogItem.salesRanks[0].classificationRanks !== null
          && this.product.catalogItem.salesRanks[0].classificationRanks.length > 0) {
        const classificationRank = this.product.catalogItem.salesRanks[0].classificationRanks.map(item => ({ title: item.title, rank: item.rank }))
        combinedData.push(...classificationRank)
      }
      if (this.product.catalogItem !== null && this.product.catalogItem.salesRanks !== null
          && this.product.catalogItem.salesRanks.length > 0
          && this.product.catalogItem.salesRanks[0] !== null
          && this.product.catalogItem.salesRanks[0].displayGroupRanks !== null
          && this.product.catalogItem.salesRanks[0].displayGroupRanks.length > 0) {
        const displayGroupRank = this.product.catalogItem.salesRanks[0].displayGroupRanks.map(item => ({ title: item.title, rank: item.rank }))
        combinedData.push(...displayGroupRank)
      }
      return combinedData
    },
  },
  watch: {
    productId: {
      handler() {
        if (this.productId !== null) {
          this.getProductDetails()
        }
      },
      immediate: true,
    },
    showProduct: {
      handler() {
        if (this.showProduct) {
          const popup = this.$refs.productDetailsRef.instance
          popup.show()
          this.rerenderKey += 1
        }
      },
    },
  },
  methods: {
    getProductDetails() {
      productsService.getProductDataById(this.productId).then(response => {
        this.product = response
        this.product = {
          ...this.product,
          isHazmat: this.product.isHazmat ? 'Yes' : 'No',
          // eslint-disable-next-line global-require
          imageUrl: this.product.smallImage ? this.product.smallImage : require('@/assets/images/undraw/no_images.svg'),
        }
      })
    },
    showImage(imageUrl) {
      this.fullImages.length = 0
      this.index = 0
      this.fullImages.push(imageUrl)
    },
    onHiding() {
      const popup = this.$refs.productDetailsRef.instance
      popup.hide()
      this.$emit('close')
    },
    openPlanPopup() {
      this.isVisible = true
    },
  },

}
</script>

<style lang="scss">
.limit-size {
  max-width: 100px;
  height: auto;
}
.square {
  width: 100px;
  height: 100px;
}
.dx-tab {
  border-radius: 3px 3px 0 0;
}
</style>
